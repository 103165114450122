import { Link } from "gatsby";
import React from "react";
import { LandingSmall } from "../components/Landing";
import { Page } from "../components/layout/Page";
import { PageLayout } from "../components/PageLayout";

const NotFoundPage = () => (
  <PageLayout>
    <LandingSmall name="404" />
    <Page>
      <Link to="/">Zpět na homepage</Link>
    </Page>
  </PageLayout>
);

export default NotFoundPage;
